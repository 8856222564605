import { useNavigate } from "react-router-dom";
import TruckIcon from "@/components/icons/TruckIcon";
import ReloadIcon from "@/components/icons/ReloadIcon";
import useRecentTruckSearches from "@/hooks/useRecentTruckSearches";

export default function Recents() {
  const { data } = useRecentTruckSearches();
  return (
    <div className="flex-col">
      <div className="text-sm font-semibold text-gray-500 px-4 pt-4">
        Recent Searches
      </div>
      {data.map((truck) => (
        <TruckCard truck={truck} />
      ))}
    </div>
  );
}

// ---- Card for each truck in search ----------------------------------
function TruckCard({ truck }) {
  const navigate = useNavigate();
  return (
    <div
      className="bg-slate-50 border mt-4 py-2 px-4 flex rounded-lg hover:bg-slate-100 cursor-pointer"
      onClick={() => {
        navigate(`/fleet/${truck.truck_id}`);
        window.location.reload();
      }}
    >
      <div className="flex flex-col justify-center p-4">
        <TruckIcon />
      </div>
      <div>
        <div className="font-semibold">{truck.registration_number}</div>
        <div className="font-light">Truck</div>
      </div>
      <div className="flex flex-col ml-auto justify-center">
        <ReloadIcon />
      </div>
    </div>
  );
}
