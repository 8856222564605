import { useNavigate } from "react-router-dom";
import { Button } from "@/components/ui/button";
import ReactSpeedometer from "react-d3-speedometer";
import truckRender from "@/assets/images/truckRender.png";
import formatNumberWithCommas from "@/utils/FormatNumberWithCommas";
import ImmobilizeDialog from "./ImmobilizeDialog";

// ---- Selected Truck Card Component ------------------------
export default function SelectedTruckCard({ selectedTruck }) {
  const navigate = useNavigate();
  return (
    <div className="bg-card flex flex-col p-4 h-[95%] w-1/3 shadow-lg rounded-lg absolute right-2 transform -translate-x-1/5 top-1/2 -translate-y-1/2">
      <CardHeader selectedTruck={selectedTruck} />
      <div className="font-semibold mt-10">VEHICLE SPEED (KM/H)</div>
      <Speedometer speed={selectedTruck.speed} />

      <div className="font-semibold mt-10">FUEL LEVEL (LITRES)</div>
      <FuelLevel fuelLevel={selectedTruck.fuel_level} />

      <div className="justify-end mt-auto flex flex-col space-y-2">
        <ImmobilizeDialog />
        <Button
          className="w-full"
          variant="outline"
          onClick={() => {
            navigate(`/fleet/${selectedTruck.truck_id}`);
          }}
        >
          View Additional Details
        </Button>
      </div>
    </div>
  );
}

// ---- Header Components  ------------------------
function TruckImage() {
  return (
    <div className="ml-auto flex justify-center">
      <img className="w-[100%]" src={truckRender} alt="truck" />
    </div>
  );
}

function CardHeader({ selectedTruck }) {
  return (
    <div className="w-full flex items-center">
      <div className="flex-col w-4/5">
        <div className="font-semibold text-xl">
          {selectedTruck.registration_number}
        </div>
        <div className="font-light">{selectedTruck.location_desc}</div>
        <div className="font-light">{selectedTruck.time_diff}</div>
      </div>
      <TruckImage />
    </div>
  );
}

// ---- Speedometer  ------------------------
function Speedometer({ speed }) {
  return (
    <div className="w-full mt-2 p-2 flex justify-center bg-slate-50 rounded-lg border">
      <ReactSpeedometer
        height={200}
        maxValue={80}
        value={speed}
        needleColor="rgb(107 114 128)"
        segments={4}
        segmentColors={[
          "rgb(219 234 254)",
          "rgb(191 219 254)",
          "rgb(147 197 253)",
          "rgb(96 165 250)",
        ]}
        textColor="black"
        ringWidth={20}
        currentValueText={speed + " km/h"}
      />
    </div>
  );
}

// ---- Speedometer  ------------------------
function segmentValueFormatter(value) {
  if (value < 100) {
    return "E";
  }
  if (value > 100 && value < 150) {
    return "";
  }
  if (value > 200 && value < 300) {
    return "1/2";
  }
  if (value > 300 && value < 400) {
    return "";
  }
  return "F";
}

function FuelLevel({ fuelLevel }) {
  const fuelColorRanges = [
    "rgb(220 38 38)",
    "rgb(239 68 68)",
    "rgb(248 113 113)",
    "rgb(252 165 165)",
  ];

  return (
    <div className="w-full mt-2 p-2 flex justify-center bg-slate-50 rounded-lg border">
      <ReactSpeedometer
        height={200}
        maxValue={450} // assuming the fuel tank capacity as a percentage
        value={fuelLevel}
        needleColor="rgb(107 114 128)"
        segments={4}
        segmentColors={fuelColorRanges}
        segmentValueFormatter={segmentValueFormatter}
        textColor="black"
        ringWidth={10}
        currentValueText={formatNumberWithCommas(fuelLevel) + " litres"}
        needleTransitionDuration={3000}
        needleTransition="easeElastic"
      />
    </div>
  );
}
