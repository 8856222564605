import fetchData from "@/utils/FetchData";
import { useState, useEffect } from "react";

// Returns location histories for entire fleet
function useLocations(refreshInterval = 600000) {
  // default to 10 minutes
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  const loadLocationData = async () => {
    setLoading(true);
    try {
      const result = await fetchData("/api/location/latest/");
      setData(result);
      setError(null);
      setLoading(false);
    } catch (e) {
      setError(e);
      setLoading(false);
    }
  };

  useEffect(() => {
    loadLocationData(); // Fetch data initially

    const intervalId = setInterval(loadLocationData, refreshInterval); // Set up periodic fetching

    return () => clearInterval(intervalId); // Clean up the interval on component unmount
  }, [refreshInterval]); // If refreshInterval changes, restart the interval

  return { data, loading, error };
}

export default useLocations;
