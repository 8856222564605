import { Dialog, DialogContent } from "@/components/ui/dialog";

import { Separator } from "@/components/ui/separator";
import { Button } from "@/components/ui/button";

export default function ViewFuelReceipt({
  truckData,
  selectedReceipt,
  isOpen,
  onClose,
  onClick,
}) {
  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent>
        {/* Displaying the selected fuel receipt details */}
        <div className="flex-col space-y-4 p-4 rounded-lg">
          <div className="flex w-full items-center justify-between mb-8">
            <div>
              <div className="text-4xl font-semibold">RECEIPT</div>
            </div>
            <div>
              <Button variant="outline" onClick={onClick}>
                Delete This Receipt
              </Button>
            </div>
          </div>

          <Separator className="pb-1 bg-blue-950" />
          <ReceiptDetail label="Truck" value={truckData?.registration_number} />
          <ReceiptDetail
            label="Date of Purchase"
            value={selectedReceipt?.date_of_purchase}
          />
          <ReceiptDetail
            label="Fuel Station"
            value={selectedReceipt?.fuel_station}
          />
          <ReceiptDetail
            label="Price per Litre"
            value={`${selectedReceipt?.price_per_litre}`}
          />
          <ReceiptDetail
            label="Volume Filled"
            value={`${selectedReceipt?.volume_filled} litres`}
          />

          <div className="pb-2" />
          <Separator className="mb-1" />

          <div className="flex w-full justify-between pt-4">
            <div>
              <div className="text-lg font-light">Total Cost</div>
            </div>
            <div>
              <div className="text-lg">
                {selectedReceipt?.total_cost}{" "}
                {selectedReceipt?.total_cost_currency}
              </div>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}

// --- Sub Component for each row --------------------------------
const ReceiptDetail = ({ label, value }) => (
  <div className="flex w-full">
    <div className="w-2/5">
      <div className="text-md font-light">{label}</div>
    </div>
    <div className="w-3/5">
      <div className="text-md text-right">{value}</div>
    </div>
  </div>
);
