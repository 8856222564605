import { useState, useEffect } from "react";
import MapLayout from "@/layouts/MapLayout";
import Map from "./Map";

export default function Live() {
  const [gpsMessages, setGpsMessages] = useState([]);
  const [latestLocation, setLatestLocation] = useState(null);

  console.log(gpsMessages);

  useEffect(() => {
    const socket = new WebSocket("wss://18.223.67.100:8000/ws/gps/");

    socket.onopen = () => {
      console.log("WebSocket connection established.");
    };

    socket.onmessage = (event) => {
      const message = JSON.parse(event.data);
      setGpsMessages((prevMessages) => [...prevMessages, message]);
      setLatestLocation(message);
    };

    socket.onerror = (error) => {
      console.error("WebSocket error:", error);
    };

    return () => {
      socket.close();
    };
  }, []);

  return (
    <MapLayout>
      <Map location={latestLocation} />
    </MapLayout>
  );
}
