import * as z from "zod";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Separator } from "@/components/ui/separator";
import { zodResolver } from "@hookform/resolvers/zod";
import { Textarea } from "@/components/ui/textarea";
import { DatePicker } from "@/components/ui/datepicker";
import supportedCurrencies from "@/lib/supportedCurrencies";
import formatNumberWithCommas from "@/utils/FormatNumberWithCommas";
import {
  Form,
  FormField,
  FormItem,
  FormLabel,
  FormControl,
} from "@/components/ui/form";
import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectItem,
  SelectContent,
} from "@/components/ui/select";

// define schema
const formSchema = z.object({
  truck: z.number(),
  part_name: z.string().max(100),
  vendor_name: z.string().max(100).optional(),
  quantity: z.number().min(1),
  price_per_unit: z.number().min(0).max(1000000),
  currency: z.string(),
  date_of_purchase: z.date(),
  notes: z.string().optional(),
});

export default function AddPartsReceiptForm({ truckData, submitPartsReceipt }) {
  // create form
  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      truck: truckData.id,
      part_name: "",
      vendor_name: "",
      quantity: "",
      price_per_unit: "",
      currency: "KES",
      date_of_purchase: new Date(),
      notes: "",
    },
  });

  // get form values
  const { getValues } = form;

  // watch quantity and price per unit to calculate total cost
  const [totalCost, setTotalCost] = useState(0);
  const { watch, setValue } = form;
  const quantity = watch("quantity");
  const pricePerUnit = watch("price_per_unit");

  useEffect(() => {
    if (quantity && pricePerUnit) {
      const totalCost = pricePerUnit * quantity;
      setTotalCost(totalCost);
    }
  }, [quantity, pricePerUnit, setValue]);

  async function onSubmit(values) {
    // Clone the values object to avoid mutating the original object
    let formattedValues = { ...values };

    // Check if the date field exists and is a Date object
    if (formattedValues.date_of_purchase instanceof Date) {
      // Format the date to YYYY-MM-DD
      const year = formattedValues.date_of_purchase.getFullYear();
      const month = (formattedValues.date_of_purchase.getMonth() + 1)
        .toString()
        .padStart(2, "0"); // Adding 1 because months are 0-indexed
      const day = formattedValues.date_of_purchase
        .getDate()
        .toString()
        .padStart(2, "0");

      formattedValues.date_of_purchase = `${year}-${month}-${day}`;
    }

    // Format the price_per_litre and total_cost with the selected currency
    formattedValues.price_per_unit_currency = values.currency;

    formattedValues.total_cost = totalCost;
    formattedValues.total_cost_currency = values.currency;

    delete formattedValues.currency;

    await submitPartsReceipt(formattedValues);
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="bg-paper">
        {/* Truck field */}
        <FormField
          name="truck"
          render={({ field }) => (
            <FormItem className="mb-4">
              <FormLabel>Truck</FormLabel>
              <FormControl>
                <Input
                  disabled
                  {...field}
                  value={truckData.registration_number}
                  placeholder={truckData.registration_number}
                />
              </FormControl>
            </FormItem>
          )}
        />

        {/* Part Name field */}
        <FormField
          control={form.control}
          name="part_name"
          render={({ field }) => (
            <FormItem className="mb-4">
              <FormLabel>Part Name</FormLabel>
              <FormControl>
                <Input
                  {...field}
                  placeholder="Part Name"
                  onChange={(e) => field.onChange(e.target.value.toUpperCase())}
                />
              </FormControl>
            </FormItem>
          )}
        />

        {/* Vendor Name field */}
        <FormField
          control={form.control}
          name="vendor_name"
          render={({ field }) => (
            <FormItem className="mb-4">
              <FormLabel>Vendor Name (optional)</FormLabel>
              <FormControl>
                <Input
                  {...field}
                  placeholder="Vendor Name"
                  onChange={(e) => field.onChange(e.target.value.toUpperCase())}
                />
              </FormControl>
            </FormItem>
          )}
        />

        {/* Quantity field */}
        <FormField
          control={form.control}
          name="quantity"
          render={({ field }) => (
            <FormItem className="mb-4">
              <FormLabel>Quantity</FormLabel>
              <FormControl>
                <Input
                  type="number"
                  {...field}
                  placeholder="Quantity"
                  onChange={(e) => field.onChange(Number(e.target.value))}
                />
              </FormControl>
            </FormItem>
          )}
        />

        {/* Price Per Unit field */}
        <FormField
          control={form.control}
          name="price_per_unit"
          render={({ field }) => (
            <FormItem className="mb-4">
              <FormLabel>Price Per Unit</FormLabel>
              <FormControl>
                <Input
                  type="number"
                  {...field}
                  placeholder="Price Per Unit"
                  step={0.01}
                  onChange={(e) => field.onChange(Number(e.target.value))}
                />
              </FormControl>
            </FormItem>
          )}
        />

        {/* Currency Select field */}
        <FormField
          control={form.control}
          name="currency"
          render={({ field }) => (
            <FormItem className="mb-4">
              <FormLabel>Currency</FormLabel>
              <FormControl>
                <Select
                  onValueChange={field.onChange}
                  defaultValue={field.value}
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Select currency" />
                  </SelectTrigger>
                  <SelectContent>
                    {supportedCurrencies.map((currency) => (
                      <SelectItem key={currency} value={currency}>
                        {currency}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </FormControl>
            </FormItem>
          )}
        />

        {/* Date of Purchase DatePicker field */}
        <FormField
          control={form.control}
          name="date_of_purchase"
          render={({ field }) => (
            <FormItem className="mb-4">
              <FormLabel>Date of Purchase</FormLabel>
              <FormControl>
                <DatePicker
                  onDateSelect={(date) =>
                    form.setValue("date_of_purchase", date)
                  }
                />
              </FormControl>
            </FormItem>
          )}
        />

        {/* Notes field */}
        <FormField
          control={form.control}
          name="notes"
          render={({ field }) => (
            <FormItem className="mb-4">
              <FormLabel>Notes (optional)</FormLabel>
              <FormControl>
                <Textarea {...field} placeholder="Additional notes" />
              </FormControl>
            </FormItem>
          )}
        />

        {/* Total Cost display */}
        <Separator className="mt-8 mb-1" />
        <div className="flex w-full justify-between px-4 my-8">
          <div className="text-lg font-light">Total Cost</div>
          <div className="text-lg">
            {formatNumberWithCommas(totalCost)} {getValues("currency")}
          </div>
        </div>

        <Button className="w-full mt-4" type="submit">
          + Add Spare Parts Receipt
        </Button>
      </form>
    </Form>
  );
}
