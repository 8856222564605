import { Navigate } from "react-router-dom";
// pages
import Home from "@/pages/home";
import Login from "@/pages/login";
import NotFound from "@/pages/404";
import InvoicesDashboard from "@/pages/dashboard/invoices";
import FuelDashboard from "@/pages/dashboard/fuel";
import PartsDashboard from "@/pages/dashboard/parts";
import Trips from "@/pages/dispatch/trips";
import Tracking from "@/pages/dispatch/tracking";
import Live from "@/pages/dispatch/live";
import Fleet from "@/pages/fleet";
import Truck from "@/pages/fleet/truck";
import RouteHistory from "@/pages/dispatch/routehistory";

// routes
const routes = [
  //{ path: "/", element: Home, isPrivate: true },
  {
    path: "/",
    element: () => <Navigate to="/dispatch/tracking" replace={true} />,
    isPrivate: true,
  },

  {
    path: "/dispatch",
    element: () => <Navigate to="/dispatch/tracking" replace={true} />,
    isPrivate: true,
  },

  { path: "/dispatch/tracking", element: Tracking, isPrivate: true },
  { path: "/dispatch/route-history", element: RouteHistory, isPrivate: true },
  { path: "/dispatch/trips", element: Trips, isPrivate: true },
  { path: "/dispatch/live", element: Live, isPrivate: true },

  //{ path: "/fleet", element: Fleet, isPrivate: true },
  {
    path: "/fleet",
    element: () => <Navigate to="/fleet/177" replace={true} />,
    isPrivate: true,
  },
  { path: "/fleet/:truckId", element: Truck, isPrivate: true },

  {
    path: "/dashboard",
    element: () => <Navigate to="/dashboard/invoices" replace={true} />,
    isPrivate: true,
  },
  {
    path: "/dashboard/invoices",
    element: InvoicesDashboard,
    isPrivate: true,
    isAdmin: true,
  },
  {
    path: "/dashboard/fuel",
    element: FuelDashboard,
    isPrivate: true,
    isAdmin: true,
  },
  {
    path: "/dashboard/parts",
    element: PartsDashboard,
    isPrivate: true,
    isAdmin: true,
  },

  { path: "/login", element: Login, isPrivate: false },
  { path: "*", element: NotFound, isPrivate: false },
];

export default routes;
