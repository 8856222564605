import LocationIcon from "@/components/icons/LocationIcon";
import formatNumberWithCommas from "@/utils/FormatNumberWithCommas";
import formatDatetimeToString from "@/utils/FormatDatetimeToString";

export default function TripsSummary({
  tripsData,
  selectedTrip,
  setSelectedTrip,
}) {
  return (
    <div className="bg-slate-50 flex flex-col p-4 h-[95%] w-1/3 shadow-lg rounded-lg absolute right-2 transform -translate-x-1/5 top-1/2 -translate-y-1/2">
      <div className="text-sm font-semibold uppercase sticky top-0 bg-slate-50 pb-2">
        Trips
      </div>
      <div className="flex flex-col overflow-auto space-y-4">
        {tripsData.map((trip) => (
          <Trip
            key={trip.id}
            tripData={trip}
            icon={<LocationIcon color="rgb(220 38 38)" strokeWidth="2" />}
            selectedTrip={selectedTrip}
            setSelectedTrip={setSelectedTrip}
          />
        ))}
      </div>
    </div>
  );
}

function Trip({ tripData, icon, selectedTrip, setSelectedTrip }) {
  return (
    <div
      className={`flex flex-col border rounded-lg p-2 py-4 cursor-pointer  ${
        selectedTrip.id === tripData.id
          ? "bg-blue-50"
          : "bg-paper hover:bg-gray-50"
      }`}
      onClick={() => {
        setSelectedTrip(tripData);
      }}
    >
      <div className="flex">
        <div className="flex items-center justify-center w-1/6">{icon}</div>
        <div className="flex flex-col space-y-2 w-5/6">
          <div
            className={`text-sm p-2 rounded-xl border ${
              selectedTrip.id === tripData.id ? "bg-gray-200" : "bg-gray-100"
            }`}
          >
            {tripData.location_start}
          </div>
          <div
            className={`text-sm p-2 rounded-xl border ${
              selectedTrip.id === tripData.id ? "bg-gray-200" : "bg-gray-100"
            }`}
          >
            {tripData.location_end}
          </div>
        </div>
      </div>

      <div className="flex flex-col w-full pl-16 space-y-4 pt-4">
        <div className="flex flex-col">
          <div className="text-xs font-bold">START TIME</div>
          <div className="text-sm">
            {formatDatetimeToString(tripData.timestamp_start)}
          </div>
        </div>

        <div className="flex flex-col">
          <div className="text-xs font-bold">END TIME</div>
          <div className="text-sm">
            {formatDatetimeToString(tripData.timestamp_end)}
          </div>
        </div>

        <div className="flex flex-col">
          <div className="text-xs font-bold">DISTANCE</div>
          <div className="text-sm">
            {formatNumberWithCommas(tripData.distance_sum)} km
          </div>
        </div>

        <div className="flex flex-col">
          <div className="text-xs font-bold">TIME ELAPSED</div>
          <div className="text-sm">{tripData.readable_time_elapsed}</div>
        </div>
      </div>
    </div>
  );
}
