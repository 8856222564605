import fetchData from "@/utils/FetchData";
import { useState, useEffect } from "react";

// Returns data for trucks based on a search query (registration number)
function useTruckSearch(registrationNumber) {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    const loadTruckData = async () => {
      if (!registrationNumber) {
        setError("No registration number provided");
        setData(null);
        setLoading(false);
        return;
      }

      try {
        // Adjust the URL path according to your API endpoint structure
        const result = await fetchData(
          `/api/trucks/search/?registration_number=${encodeURIComponent(
            registrationNumber
          )}`
        );
        setData(result);
        setError(null);
      } catch (e) {
        setError(e.message || "An error occurred while fetching data");
      } finally {
        setLoading(false);
      }
    };

    loadTruckData();
  }, [registrationNumber]); // Dependency array includes registrationNumber

  return { data, loading, error };
}

export default useTruckSearch;
