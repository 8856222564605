import { useState } from "react";
import { postData } from "@/utils/PostData";

function useCreateRecentTruckSearch() {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const createRecentTruckSearch = async (truckId) => {
    setLoading(true);
    setData(null);
    setError(null);

    try {
      const result = await postData("/api/trucks/create-recent-search/", {
        truck_id: truckId,
      });
      setData(result);
    } catch (e) {
      setError(e.message || "An error occurred while updating search history");
    } finally {
      setLoading(false);
    }
  };

  return { createRecentTruckSearch, data, loading, error };
}

export default useCreateRecentTruckSearch;
