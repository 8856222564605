import { useState, useEffect } from "react";
import TrackingMap from "./TrackingMap";
import TrackingPanel from "./TrackingPanel";
import MapLayout from "@/layouts/MapLayout";
import ControlPanel from "@/components/controlpanel";
import useLocations from "@/hooks/useLocations";
import { LoadingSpinner } from "@/components/ui/loadingSpinner";

const MOBILE_WIDTH = 768; // Define a width threshold for mobile devices

export default function Tracking() {
  // location data for fleet
  const { data, loading } = useLocations();

  // single truck selected
  const [selectedTruck, setSelectedTruck] = useState(null);

  // mobile state
  const [isMobile, setIsMobile] = useState(window.innerWidth < MOBILE_WIDTH);

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen w-screen">
        <LoadingSpinner />
      </div>
    );
  }

  if (data) {
    if (isMobile) {
      // Return mobile specific layout or components
      return (
        <MapLayout>
          <TrackingMap
            locations={data}
            selectedTruck={selectedTruck}
            setSelectedTruck={setSelectedTruck}
          />
        </MapLayout>
      );
    } else {
      return (
        <MapLayout>
          <ControlPanel>
            <TrackingPanel
              data={data}
              selectedTruck={selectedTruck}
              setSelectedTruck={setSelectedTruck}
            />
          </ControlPanel>
          <TrackingMap
            locations={data}
            selectedTruck={selectedTruck}
            setSelectedTruck={setSelectedTruck}
          />
        </MapLayout>
      );
    }
  }
}
