import { useState } from "react";
import KeyMetrics from "./KeyMetrics";
import useTruck from "@/hooks/useTruck";
import TruckOverview from "./TruckOverview";
import AppLayout from "@/layouts/AppLayout";
import { useParams } from "react-router-dom";
import dateOptions from "@/lib/dateOptions";
import CurrentLocation from "./CurrentLocation";
import TopButtonRow from "./toprow/TopButtonRow";
import RecentFuelExpense from "./tables/RecentFuelExpense";
import RecentPartsExpense from "./tables/RecentPartsExpense";
import { LoadingSpinner } from "@/components/ui/loadingSpinner";
import RecentInvoices from "./tables/RecentInvoices";
import DriverScorecard from "./DriverScorecard";

export default function Truck() {
  const { truckId } = useParams();
  const { data: truckData, loading } = useTruck(truckId);

  const [currency, setCurrency] = useState("USD");
  const [startDate, setStartDate] = useState(
    dateOptions["Last Month"]["startDate"]
  );
  const [endDate, setEndDate] = useState(dateOptions["Last Month"]["endDate"]);
  const [truckProfit, setTruckProfit] = useState({
    amount: 0,
    currency: "KES",
  });

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen w-screen">
        <LoadingSpinner />
      </div>
    );
  }

  if (truckData) {
    return (
      <AppLayout>
        <TopButtonRow
          setCurrency={setCurrency}
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          truckData={truckData}
        />
        <div className="flex flex-col mt-4">
          <div className="flex space-x-4">
            <div className="w-2/3 h-screen[80%]">
              <TruckOverview
                data={truckData}
                currency={currency}
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                setTruckProfit={setTruckProfit}
              />
            </div>
            <div className="w-1/5">
              <KeyMetrics
                data={truckData}
                currency={currency}
                startDate={startDate}
                endDate={endDate}
                truckProfit={truckProfit}
              />
            </div>
            <div className="w-1/5">
              <CurrentLocation data={truckData} />
            </div>
          </div>

          <div className="flex space-x-4 mt-4">
            <div className="flex w-full space-x-4">
              <RecentInvoices
                data={truckData}
                currency={currency}
                startDate={startDate}
                endDate={endDate}
              />
              <DriverScorecard
                truckData={truckData}
                startDate={startDate}
                endDate={endDate}
              />
            </div>
          </div>

          <div className="flex space-x-4 mt-4">
            <div className="flex w-full space-x-4">
              <RecentFuelExpense
                data={truckData}
                currency={currency}
                startDate={startDate}
                endDate={endDate}
              />
              <RecentPartsExpense
                data={truckData}
                currency={currency}
                startDate={startDate}
                endDate={endDate}
              />
            </div>
          </div>
        </div>
      </AppLayout>
    );
  }
}
