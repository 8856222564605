import { useState } from "react";
import useGetTrips from "@/hooks/useGetTrips";
import { Separator } from "@/components/ui/separator";
import CustomDatePicker from "../routehistory/CustomDatePicker";
import AutocompleteTruckSearch from "@/components/search/AutocompleteTruckSearch";

export default function TripsPanel({ setTripsData }) {
  const [selectedTruck, setSelectedTruck] = useState({ id: null });
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const { data: tripsData, loading: loadingTripsData } = useGetTrips(
    startDate,
    endDate,
    selectedTruck.id
  );

  setTripsData(tripsData);

  console.log(tripsData);

  return (
    <div className="h-full flex flex-col p-2">
      <div className="flex-grow space-y-4">
        <AutocompleteTruckSearch setSelectedTruck={setSelectedTruck} />
        <CustomDatePicker setStartDate={setStartDate} setEndDate={setEndDate} />
        <Separator />
        <div className="bg-slate-50 rounded-lg p-6 flex-col space-y-4">
          <div className="text-sm font-semibold uppercase">Trip Analysis</div>
          <div className="text-md  text-gray-600">
            Explore individual trips and idle periods. Choose a vehicle and
            specify a day to get started. <br />
            <br />
            Trip Analysis presents a visual timeline of each trip and idling
            session, offering vital statistics like duration, distance, and
            stationary times. Gain in-depth understanding of your fleet's
            operational patterns and optimize for efficiency.
          </div>
        </div>
      </div>
    </div>
  );
}
