import { useState, useEffect } from "react";
import TruckIcon from "@/components/icons/TruckIcon";
import { Button } from "@/components/ui/button";
import formatNumberWithCommas from "@/utils/FormatNumberWithCommas";
import { Separator } from "@/components/ui/separator";

// ---- Panel Component -------------------------------------
export default function TrackingPanel({
  data,
  selectedTruck,
  setSelectedTruck,
}) {
  // initialize list of truck cards
  const [listedTrucks, setListedTrucks] = useState([]);

  // initialize state of search bar
  const [inputValue, setInputValue] = useState("");

  // initialize number of records
  const [lenRecords, setLenRecords] = useState(listedTrucks.length);

  // update state when input changes
  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  // use effect to refresh list when search input changes
  useEffect(() => {
    const results = searchTrucksByInput(data, inputValue, 20);
    setListedTrucks(results);
    setLenRecords(results.length);
  }, [data, inputValue]); // adding data ensures this runs on mount too

  return (
    <div className="h-full flex flex-col p-2 overflow-y-auto">
      {selectedTruck && (
        <input
          className="mt-4 flex h-10 text-sm w-full rounded-lg border border-input bg-background px-3 py-2 text-lg placeholder:text-muted-foreground disabled:cursor-not-allowed disabled:opacity-50 focus-visible:outline-none"
          type="text"
          placeholder="Search for a vehicle..."
          disabled
        />
      )}

      {!selectedTruck && (
        <input
          className="mt-4 flex h-10 text-sm w-full px-4 rounded-lg border border-input bg-background px-3 py-3 text-lg placeholder:text-muted-foreground disabled:cursor-not-allowed disabled:opacity-50 focus-visible:outline-none"
          type="text"
          placeholder="Search for a vehicle..."
          value={inputValue}
          onChange={handleInputChange}
        />
      )}

      <Separator className="mt-4" />

      {selectedTruck && (
        <SelectedTruckCard truck={selectedTruck}></SelectedTruckCard>
      )}

      {selectedTruck == null &&
        listedTrucks.map((truck, n) => (
          <TruckCard
            key={`truck-card${n}`}
            truck={truck}
            setSelectedTruck={setSelectedTruck}
          />
        ))}

      {!selectedTruck && (
        <div className="pt-4 flex justify-end mt-auto justify-center">
          <div className="text-gray-500 text-sm font-semibold">
            Showing {lenRecords} vehicles
          </div>
        </div>
      )}
      {selectedTruck && (
        <div className="flex justify-end mt-auto">
          <Button
            className="w-full"
            onClick={() => {
              setSelectedTruck(null);
            }}
          >
            Reset Map
          </Button>
        </div>
      )}
    </div>
  );
}

// ---- Function to Search Through Trucks Array -------------------------------------
function searchTrucksByInput(trucksArray, inputString, n) {
  // Check if the search string is empty and return the first n trucks
  if (inputString.trim() === "") {
    return trucksArray.slice(0, n);
  }

  // Strip the input string of whitespace once
  const strippedInputString = inputString.replace(/\s+/g, "").toLowerCase();

  // Filter the trucks array and return only the first n results
  return trucksArray
    .filter((truckObj) => {
      // Ensure truck property exists and strip its whitespace before comparison
      return (
        truckObj.registration_number &&
        truckObj.registration_number
          .replace(/\s+/g, "")
          .toLowerCase()
          .includes(strippedInputString)
      );
    })
    .slice(0, n);
}

// ---- Card for each truck in control panel ----------------------------------
function TruckCard({ truck, setSelectedTruck }) {
  return (
    <div
      className="bg-slate-50 border mt-4 py-2 px-4 flex rounded-lg hover:bg-slate-100 cursor-pointer"
      onClick={() => {
        setSelectedTruck(truck);
      }}
    >
      <div className="flex flex-col justify-center p-4">
        <TruckIcon />
      </div>
      <div>
        <div className="font-semibold">{truck.registration_number}</div>
        <div className="font-light">{truck.location_desc}</div>
        <div className="font-light">{truck.time_diff}</div>
      </div>
      <div className="flex flex-col ml-auto justify-center">
        <div className="text-sm">
          Fuel Level: {formatNumberWithCommas(truck.fuel_level)} L
        </div>
        <div className="text-sm">Speed: {truck.speed} km/h</div>
      </div>
    </div>
  );
}

function SelectedTruckCard({ truck }) {
  return (
    <div className="bg-blue-100 border mt-4 py-2 px-4 flex rounded-lg cursor-pointer">
      <div className="flex flex-col justify-center p-4">
        <TruckIcon />
      </div>
      <div>
        <div className="font-semibold">{truck.registration_number}</div>
        <div className="font-light">{truck.location_desc}</div>
        <div className="font-light">{truck.time_diff}</div>
      </div>
      <div className="flex flex-col ml-auto justify-center">
        <div className="text-sm">
          Fuel Level: {formatNumberWithCommas(truck.fuel_level)} L
        </div>
        <div className="text-sm">Speed: {truck.speed} km/h</div>
        <div className="text-sm">Status: {truck.truck_status}</div>
      </div>
    </div>
  );
}
