export default function formatDatetimeToString(input) {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const date = new Date(input);
  const year = date.getFullYear();
  const month = months[date.getMonth()];
  const day = date.getDate();
  const hour = date.getHours();
  const minute = date.getMinutes();

  const suffix = (day) => {
    if (day > 3 && day < 21) return "th";
    switch (day % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  const formattedTime =
    (hour % 12 || 12) +
    ":" +
    (minute < 10 ? "0" : "") +
    minute +
    (hour < 12 ? "AM" : "PM");

  return `${month} ${day}${suffix(day)}, ${year} - ${formattedTime}`;
}
