import { Link } from "react-router-dom";
import { Separator } from "@/components/ui/separator";

export default function Menu({ activeState }) {
  return (
    <div className="flex justify-around">
      <TabLink
        label="Tracking"
        to="/dispatch/tracking"
        isActive={activeState === "tracking"}
      />
      <Separator orientation="vertical" />
      <TabLink
        label="Trip Analysis"
        to="/dispatch/trips"
        isActive={activeState === "trips"}
      />
      <Separator orientation="vertical" />
      <TabLink
        label="Route History"
        to="/dispatch/route-history"
        isActive={activeState === "route-history"}
      />
    </div>
  );
}

const TabLink = ({ label, to, isActive }) => (
  <Link
    to={to}
    className={`cursor-pointer text-blue-950 uppercase ${
      isActive ? "font-bold border-b-2 border-blue-950" : ""
    }`}
  >
    {label}
  </Link>
);
