import MapLayout from "@/layouts/MapLayout";
import ControlPanel from "@/components/controlpanel";
import TripsPanel from "./TripsPanel";
import TripsMap from "./TripsMap";
import { useState } from "react";

export default function Trips() {
  const [selectedTrip, setSelectedTrip] = useState({});
  const [tripsData, setTripsData] = useState([]);

  return (
    <MapLayout>
      <ControlPanel>
        <TripsPanel setTripsData={setTripsData} />
      </ControlPanel>
      <TripsMap
        tripsData={tripsData}
        selectedTrip={selectedTrip}
        setSelectedTrip={setSelectedTrip}
      />
    </MapLayout>
  );
}
