export default function calculateCurvePoints(start, end, curvature = 0.2) {
  const numPoints = 100;
  const points = [];

  // Calculating the midpoint
  const midLat = (start.lat() + end.lat()) / 2;
  const midLng = (start.lng() + end.lng()) / 2;

  // Control point
  // Shifting the midpoint perpendicularly to the line between start and end
  const controlPointLng = midLng + curvature * (start.lat() - end.lat());

  for (let i = 0; i < numPoints; i++) {
    const t = i / (numPoints - 1);
    const lat =
      (1 - t) * (1 - t) * start.lat() +
      2 * (1 - t) * t * midLat +
      t * t * end.lat();
    const lng =
      (1 - t) * (1 - t) * start.lng() +
      2 * (1 - t) * t * controlPointLng +
      t * t * end.lng();
    points.push({ lat, lng });
  }

  return points;
}
