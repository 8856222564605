import TopRow from "../toprow";
import { useState } from "react";
import dateOptions from "@/lib/dateOptions";
import AppLayout from "@/layouts/AppLayout";
import InvoicesHeadline from "./InvoicesHeadline";
import InvoiceStatsTable from "./InvoiceStatsTable";
import DistributionBarChart from "@/components/barchart";
import useTotalInvoiceAmount from "@/hooks/useTotalInvoiceAmount";
import useInvoiceAmountByTruck from "@/hooks/useInvoiceAmountByTruck";
import useInvoiceStatsByClient from "@/hooks/useInvoiceStatsByClient";
import useInvoiceAmountOverTime from "@/hooks/useInvoiceAmountOverTime";
import InvoiceAmountOverTime from "./InvoiceAmountOverTime";

export default function InvoicesDashboard() {
  const [currency, setCurrency] = useState("USD");
  const [startDate, setStartDate] = useState(
    dateOptions["Last Month"]["startDate"]
  );
  const [endDate, setEndDate] = useState(dateOptions["Last Month"]["endDate"]);

  const { data: totalInvoiceAmount, loading: loadingTotalInvoiceAmount } =
    useTotalInvoiceAmount(startDate, endDate, currency);

  const { data: invoiceAmountByTruck, loading: loadingInvoiceAmountByTruck } =
    useInvoiceAmountByTruck(startDate, endDate, currency);

  const { data: invoiceStats, loading: loadingInvoiceStats } =
    useInvoiceStatsByClient(startDate, endDate, currency);

  const { data: invoiceAmountOverTime, loading: loadingInvoiceAmountOverTime } =
    useInvoiceAmountOverTime(startDate, endDate, currency);

  return (
    <AppLayout>
      <TopRow
        setCurrency={setCurrency}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        prevStartDate={
          totalInvoiceAmount ? totalInvoiceAmount.prev_start_date : null
        }
        prevEndDate={
          totalInvoiceAmount ? totalInvoiceAmount.prev_end_date : null
        }
      />
      <InvoicesHeadline
        startDate={startDate}
        endDate={endDate}
        currency={currency}
        totalInvoiceAmount={totalInvoiceAmount}
        loadingTotalInvoiceAmount={loadingTotalInvoiceAmount}
      />
      <DistributionBarChart
        data={invoiceAmountByTruck}
        loading={loadingInvoiceAmountByTruck}
        chartTitle="Total Revenue by Truck"
        dataKey="total_invoice_amount"
        dataLabel="Total Revenue"
      />

      <div className="flex w-full space-x-2 h-2/5">
        <InvoiceStatsTable
          data={invoiceStats}
          loading={loadingInvoiceStats}
          endDate={endDate}
        />
        <InvoiceAmountOverTime
          data={invoiceAmountOverTime}
          loading={loadingInvoiceAmountOverTime}
        />
      </div>
    </AppLayout>
  );
}
