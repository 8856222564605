import fetchData from "@/utils/FetchData";
import { useState, useEffect } from "react";

// Returns list of all trucks
function useRecentTruckSearches() {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  const loadData = async () => {
    setLoading(true);
    try {
      const result = await fetchData("/api/trucks/recent-searches/");
      setData(result);
      setError(null);
    } catch (e) {
      setError(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  return { data, loading, error };
}

export default useRecentTruckSearches;
