import { useState, useEffect } from "react";
import GoogleMapReact from "google-map-react";
import truckIcon from "@/assets/truck-icon.svg";
import MarkerClusterer from "@google/markerclusterer";
import SelectedTruckCard from "./SelectedTruckCard";

// Map Component
// -------------------------------------------------------------------- //
export default function Map({ locations, selectedTruck, setSelectedTruck }) {
  // state
  const [map, setMap] = useState(null);
  const [maps, setMaps] = useState(null);
  const [markers, setMarkers] = useState([]);
  const [markerClusterer, setMarkerClusterer] = useState(null);

  // map props
  const defaultProps = {
    center: {
      lat: 0,
      lng: 35.5,
    },
    zoom: 6.5,
  };

  const onGoogleApiLoaded = ({ map, maps }) => {
    setMap(map);
    setMaps(maps);
  };

  // create markers from truck data
  useEffect(() => {
    if (map && maps) {
      // Clear existing markers and clusterer
      if (markerClusterer) {
        markerClusterer.clearMarkers();
      }
      markers.forEach((marker) => marker.setMap(null));
      setMarkers([]);

      if (selectedTruck) {
        // If selectedTruck is not null, create and display only the selected truck's marker
        const selectedTruckPosition = {
          lat: selectedTruck.latitude,
          lng: selectedTruck.longitude,
        };

        const mapCenterPosition = {
          lat: selectedTruck.latitude,
          lng: selectedTruck.longitude + 0.05,
        };

        const marker = new maps.Marker({
          position: selectedTruckPosition,
          map: map,
          label: selectedTruck.truck,
          icon: {
            url: truckIcon,
            scaledSize: new maps.Size(30, 30),
            labelOrigin: new maps.Point(15, 40),
            rotation: selectedTruck.heading,
          },
        });

        // Center the map on the selected truck and zoom in
        map.setCenter(mapCenterPosition);
        map.setZoom(12); // Zoom level can be adjusted based on preference

        setMarkers([marker]);
      } else if (locations.length > 0) {
        // Restore the default zoom level if no truck is selected
        map.setZoom(defaultProps.zoom);

        // Create markers for all trucks and use the clusterer
        const newMarkers = locations.map((location) => {
          const marker = new maps.Marker({
            position: { lat: location.latitude, lng: location.longitude },
            label: location.truck,
            icon: {
              url: truckIcon,
              scaledSize: new maps.Size(30, 30),
              labelOrigin: new maps.Point(15, 40),
              rotation: location.heading,
            },
          });

          marker.addListener("click", () => {
            setSelectedTruck(location);
          });

          return marker;
        });

        // Create a new clusterer with the new markers
        const newMarkerClusterer = new MarkerClusterer(map, newMarkers, {
          imagePath:
            "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
        });

        setMarkers(newMarkers);
        setMarkerClusterer(newMarkerClusterer);
      } else {
        // Restore the default zoom level and center if there are no locations
        map.setCenter(defaultProps.center);
        map.setZoom(defaultProps.zoom);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, maps, locations, selectedTruck]);

  return (
    <div className="w-full h-full pl-4 relative">
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyDHS1vzHhthX0r8z6NARZ8ofngg9boXHIU" }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={onGoogleApiLoaded}
        options={{ mapId: "79ba85b61acfbcf1", disableDefaultUI: true }}
      />
      {selectedTruck && <SelectedTruckCard selectedTruck={selectedTruck} />}
    </div>
  );
}
