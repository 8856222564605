import fetchData from "@/utils/FetchData";
import { useState, useEffect } from "react";

// Hook for fetching truck metrics data
function useScorecard(startDate, endDate, truckId) {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    const loadData = async () => {
      if (!startDate || !endDate || !truckId) {
        setError("startDate, endDate, or truckId missing");
        setData(null);
        setLoading(false);
        return;
      }

      try {
        let url = `/api/location/scorecard?start_date=${startDate}&end_date=${endDate}&truck_id=${truckId}`;
        const result = await fetchData(url);
        setData(result);
        setError(null);
      } catch (e) {
        setError(e);
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, [startDate, endDate, truckId]);

  return { data, loading, error };
}

export default useScorecard;
