import fetchData from "@/utils/FetchData";
import { useState, useEffect } from "react";

// Returns data for a single truck
function useTruck(truckId) {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    const loadTruckData = async () => {
      if (!truckId) {
        setError("No truck ID provided");
        setData(null);
        setLoading(false);
        return;
      }

      try {
        const result = await fetchData(`/api/trucks/${truckId}/`);
        setData(result);
        setError(null);
      } catch (e) {
        setError(e);
      } finally {
        setLoading(false);
      }
    };

    loadTruckData();
  }, [truckId]);

  return { data, loading, error };
}

export default useTruck;
