import fetchData from "@/utils/FetchData";
import { useState, useEffect } from "react";

// Returns total fuel consumption for a truck (if specified), date range
function useTotalFuelConsumption(startDate, endDate, truckId = null) {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    const loadTotalFuelConsumptionData = async () => {
      if (!startDate || !endDate) {
        setError("startDate or endDate missing");
        setData(null);
        setLoading(false);
        return;
      }

      try {
        let url = `/api/financials/fuel/total-consumption?start_date=${startDate}&end_date=${endDate}`;
        if (truckId) {
          url += `&truck_id=${truckId}`;
        }
        const result = await fetchData(url);
        setData(result);
        setError(null);
      } catch (e) {
        setError(e);
      } finally {
        setLoading(false);
      }
    };

    loadTotalFuelConsumptionData();
  }, [startDate, endDate, truckId]);

  return { data, loading, error };
}

export default useTotalFuelConsumption;
