import { useNavigate } from "react-router-dom";
import TruckIcon from "@/components/icons/TruckIcon";
import useCreateRecentTruckSearch from "@/hooks/useCreateRecentTruckSearch";

export default function SearchResults({ data }) {
  const { createRecentTruckSearch } = useCreateRecentTruckSearch();

  return (
    <div className="flex-col">
      <div className="text-sm font-semibold text-gray-500 px-4 pt-4">
        Search Results
      </div>
      {data.map((truck) => (
        <TruckCard
          key={truck.id}
          truck={truck}
          createRecentTruckSearch={createRecentTruckSearch}
        />
      ))}
    </div>
  );
}

// ---- Card for each truck in search ----------------------------------
function TruckCard({ truck, createRecentTruckSearch }) {
  const navigate = useNavigate();
  return (
    <div
      className="bg-blue-50 border mt-4 py-2 px-4 flex rounded-lg hover:bg-blue-100 cursor-pointer"
      onClick={() => {
        createRecentTruckSearch(truck.id);
        navigate(`/fleet/${truck.id}`);
        window.location.reload();
      }}
    >
      <div className="flex flex-col justify-center p-4">
        <TruckIcon />
      </div>
      <div>
        <div className="font-semibold">{truck.registration_number}</div>
        <div className="font-light">Truck</div>
      </div>
    </div>
  );
}
