import GoogleMapReact from "google-map-react";
import React, { useEffect, useState } from "react";
import TripsSummary from "./TripsSummary";
import calculateCurvePoints from "./CalculateCurvePoints";

export default function TripsMap({ tripsData, selectedTrip, setSelectedTrip }) {
  const [map, setMap] = useState(null);
  const [mapsApi, setMapsApi] = useState(null);
  const [markers, setMarkers] = useState([]);
  const [polyline, setPolyline] = useState(null);

  // Default map properties
  const defaultProps = {
    center: {
      lat: 0,
      lng: 40,
    },
    zoom: 6.5,
  };

  const handleApiLoaded = (map, maps) => {
    setMap(map);
    setMapsApi(maps);
  };

  const clearMapObjects = () => {
    markers.forEach((marker) => marker.setMap(null));
    setMarkers([]);

    if (polyline) {
      polyline.setMap(null);
      setPolyline(null);
    }
  };

  useEffect(() => {
    if (map && mapsApi && selectedTrip) {
      clearMapObjects();

      const startLatLng = new mapsApi.LatLng(
        selectedTrip.latitude_start,
        selectedTrip.longitude_start
      );
      const endLatLng = new mapsApi.LatLng(
        selectedTrip.latitude_end,
        selectedTrip.longitude_end
      );

      const startMarker = new mapsApi.Marker({
        position: startLatLng,
        map: map,
        title: selectedTrip.location_start,
      });
      const endMarker = new mapsApi.Marker({
        position: endLatLng,
        map: map,
        title: selectedTrip.location_end,
      });
      setMarkers([startMarker, endMarker]);

      const curvePoints = calculateCurvePoints(startLatLng, endLatLng);
      const newPolyline = new mapsApi.Polyline({
        path: curvePoints.map((p) => new mapsApi.LatLng(p.lat, p.lng)),
        geodesic: false,
        strokeColor: "#FF0000",
        strokeOpacity: 1.0,
        strokeWeight: 2,
        icons: [
          {
            icon: {
              path: mapsApi.SymbolPath.FORWARD_CLOSED_ARROW,
              scale: 5,
              strokeColor: "#FF0000",
              fillColor: "#FF0000",
              fillOpacity: 1,
            },
            offset: "50%", // Places the arrow at the end of the polyline
          },
        ],
      });
      newPolyline.setMap(map);

      newPolyline.setMap(map);
      setPolyline(newPolyline);

      // Only recenter and rezoom the map if lat, lng and zoom are valid numbers
      if (
        !isNaN(selectedTrip.center_latitude) &&
        !isNaN(selectedTrip.center_longitude) &&
        !isNaN(selectedTrip.zoom_level)
      ) {
        const newLongitude =
          selectedTrip.center_longitude +
          (selectedTrip.zoom_level < 10 ? 2 : 0);
        const center = new mapsApi.LatLng(
          selectedTrip.center_latitude,
          newLongitude
        );

        // Smoothly pan to the new center
        map.panTo(center);

        // Delay zooming for smooth transition
        setTimeout(() => {
          map.setZoom(selectedTrip.zoom_level);
        }, 1000); // 500ms delay for the zoom animation
      }
    }
  }, [map, mapsApi, selectedTrip]);

  return (
    <div className="w-full h-full pl-4 relative">
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyDHS1vzHhthX0r8z6NARZ8ofngg9boXHIU" }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
        yesIWantToUseGoogleMapApiInternals
        options={{ mapId: "79ba85b61acfbcf1", disableDefaultUI: true }}
        onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
      />
      {tripsData && (
        <TripsSummary
          tripsData={tripsData}
          selectedTrip={selectedTrip}
          setSelectedTrip={setSelectedTrip}
        />
      )}
    </div>
  );
}
